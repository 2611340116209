
import {FormControl, ValidationErrors} from '@angular/forms';
import {ValidatorUtils} from './validator-utils';

export class CustomValidators {

  static required(control: FormControl, trim = true): ValidationErrors {
    return ValidatorUtils.isEmptyString(control.value, trim)
      ? { required: true } : null;
  }

}
